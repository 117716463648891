import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import RichText from "../components/richText"
import CTA from "../components/cta"

export const query = graphql`
  query ($slug: String) {
    sanityThankYouPage(slug: {current: {eq: $slug}}) {
      title
      slug {current}
      _rawBody(resolveReferences: {maxDepth: 10})
      _rawCta
      _rawGlobalCta(resolveReferences: {maxDepth: 10})
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const ThankYouPageTemplate = (props) => {
  const page = props.data.sanityThankYouPage
  const cta = page._rawGlobalCta ? page._rawGlobalCta.cta : page._rawCta
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description = page.seo && page.seo.description ? page.seo.description : null
  const image = page.seo && page.seo.openGraphImage && page.seo.openGraphImage.asset !== null ? page.seo.openGraphImage.asset.url : null

  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SEO
        title={page.title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero heroTitle={page.title} theme="light" graph="radarPink" />
      <section className="grid">
        <div className="gridItem xlarge-10 xlarge-offset-1" style={{marginBottom: `32px`}}>
          <RichText blocks={page._rawBody} />
        </div>
      </section>
      {cta && <CTA {...cta} />}
    </Layout>
  )
}

export default ThankYouPageTemplate
